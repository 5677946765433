import { Button, Container, Stack, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useLanguage } from "../../../state/language";
import CardPrice from "../../pricing/components/CardPrice";
import { CompanyInput } from "inspector-common/lib/model/company/Company";
import { Address } from "inspector-common/lib/model/common/Address";
import { isValidEmail, isValidPhoneNumber, isValidVat } from "inspector-common/lib/utils/validation";
import { useRegisterCompany } from "../../../api/hooks";
import { useSubscriptionType } from "../functions/hooks";
import { useState } from "react";
import { Link } from "react-router-dom";

interface CompanySignupPageProps {};

const MIN_LENGTH = 3;
const MAX_LENGTH = 100;


const styles = {
    container: {
        maxWidth: "500px",
        
        p: {xs: 1, lg: 4},
        mt: {lg: 10, md: 6, sx: 0},
        boxShadow: 12
    },
    textField: {
        width: "100%",
    },
    button: {
        width: "100%",
        mb: 8
    },
    title: {
        fontWeight: "bold",
        fontSize: 24,
        textAlign: "center",
        mb: 2
    }
}

export default function CompanySignupPage(props: CompanySignupPageProps) {
    const language = useLanguage();
    const { execute: registerCompany, isLoading } = useRegisterCompany()
    const [success, setSuccess] = useState(false);
    const { config, subscriptionType } = useSubscriptionType();

    const formik = useFormik<CompanyInput>({
        initialValues: {
            name: "",
            address: {
                city: "",
                zip_code: "",
                street: "",
                number: "",
                bus: ""
            },
            vat: "",
            phone: "",
            email: "",
            subscription_type: subscriptionType
        },
        onSubmit: () => {
            registerCompany({company: formik.values}).then(() => setSuccess(true));
        },
        validateOnBlur: true,
        validateOnChange: true,
        validate: (values) => {
            let errors: any = {};
            if ((MIN_LENGTH > values.name.length || values.name.length > MAX_LENGTH)) {
                errors.name = language.company.registration.errors.length(MIN_LENGTH, MAX_LENGTH);
            }
            const setAddressError = (field: keyof Address, error: string) => {
                const addressError = errors.address ?? {};
                errors.address = {...addressError, [field]: error}
            }
            if (2 > values.address.city.length || values.address.city.length > MAX_LENGTH) {
                setAddressError("city", language.company.registration.errors.length(2, MAX_LENGTH));
            }
            if (MIN_LENGTH > values.address.street.length || values.address.street.length > MAX_LENGTH) {
                setAddressError("street", language.company.registration.errors.length(MIN_LENGTH, MAX_LENGTH));
            }
            if (3 > values.address.zip_code.length || values.address.zip_code.length > 10) {
                setAddressError("zip_code", language.company.registration.errors.length(3, 10));
            } 
            if (1 > values.address.number.length || values.address.number.length > 10) {
                setAddressError("number", language.company.registration.errors.length(1, 10));
            } 
            if (!isValidVat(formik.values.vat)) {
                errors.vat = language.company.registration.errors.vat;
            }
            if (!isValidEmail(formik.values.email)) {
                errors.email = language.company.registration.errors.email
            }
            if (!isValidPhoneNumber(formik.values.phone.replaceAll(" ", ""))) {
                errors.phone = language.company.registration.errors.phone;
            }
            return errors;
        }
    });

    if (success) {
        return <Container sx={styles.container} >
            <Typography>{language.company.registration.seeCompanyEmail}</Typography>
            <Typography>{language.company.registration.resendEmailMessage}</Typography>
        </Container>
    }

    return <Container sx={styles.container} maxWidth={false}>
        <Stack direction="column" spacing={2}>
            <Typography sx={styles.title}>{config.title}</Typography>
            <CardPrice
                price={config.subscriptionPrice}
                comment={config.pricingComment}
                highlighted={config.highlighted}
            />
            <TextField 
                name="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                variant="outlined"
                label={language.company.name}
                sx={styles.textField}
                error={formik.touched.name && !!formik.errors.name}
                helperText={formik.touched.name && formik.errors.name}
            />
            <TextField 
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                variant="outlined"
                label={language.users.email}
                sx={styles.textField}
                error={formik.touched.email && !!formik.errors.email}
                helperText={formik.touched.email && formik.errors.email}
            />
            <TextField 
                name="address.zip_code"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                variant="outlined"
                label={language.assignments.attributes.address.zipCode}
                sx={styles.textField}
                error={formik.touched.address?.zip_code && !!formik.errors.address?.zip_code}
                helperText={formik.touched.address?.zip_code && formik.errors.address?.zip_code}
            />
            <TextField 
                name="address.city"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                variant="outlined"
                label={language.assignments.attributes.address.city}
                sx={styles.textField}
                error={formik.touched.address?.city && !!formik.errors.address?.city}
                helperText={formik.touched.address?.city && formik.errors.address?.city}
            />
            <TextField 
                name="address.street"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                variant="outlined"
                label={language.assignments.attributes.address.street}
                sx={styles.textField}
                error={formik.touched.address?.street && !!formik.errors.address?.street}
                helperText={formik.touched.address?.street && formik.errors.address?.street}
            />
            <Stack direction="row" spacing={1}>
                <TextField 
                    name="address.number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant="outlined"
                    label={language.assignments.attributes.address.number}
                    sx={styles.textField}
                    error={formik.touched.address?.number && !!formik.errors.address?.number}
                    helperText={formik.touched.address?.number && formik.errors.address?.number}
                />
                <TextField 
                    name="address.bus"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant="outlined"
                    label={language.assignments.attributes.address.bus}
                    sx={styles.textField}
                    error={formik.touched.address?.bus && !!formik.errors.address?.bus}
                    helperText={formik.touched.address?.bus && formik.errors.address?.bus}
                />

            </Stack>

            
            <TextField 
                name="vat"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                variant="outlined"
                label={language.company.vat}
                sx={styles.textField}
                error={formik.touched.vat && !!formik.errors.vat}
                helperText={formik.touched.vat && formik.errors.vat}
            />
            <TextField 
                name="phone"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                variant="outlined"
                label={language.company.phone}
                sx={styles.textField}
                error={formik.touched.phone && !!formik.errors.phone}
                helperText={formik.touched.phone && formik.errors.phone}
            />

<           Typography>
                {language.portal.readAndAccepted} <Link to={"/terms"}>{language.portal.termsAndConditions}</Link>
            </Typography>
            <Button
                variant="contained"
                sx={styles.button}
                disabled={isLoading}
                onClick={() => formik.handleSubmit()}
            >
                {language.portal.signup}
            </Button>
        </Stack>
    </Container>
};

