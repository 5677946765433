import { Button, Container, Stack, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { Role } from "inspector-common/lib/model";
import { CreateFirstUserRequest } from "inspector-common/lib/api/calls";
import { useLanguage } from "../../../state/language";
import { useCreateFirstUser } from "../../../api/hooks";
import { Link } from "react-router-dom";

interface UserSignUpFormProps {
    company_id: string
    code: string
    onSuccess: () => void
}
const styles = {
    container: {
        width: "500px",
        p: 4,
        mt: {lg: 10, md: 6, sx: 0},
        boxShadow: 12
    },
    textField: {
        width: "100%",
    },
    button: {}
}

export default function UserSignUpForm(props: UserSignUpFormProps) {
    const language = useLanguage();
    const { execute: createUser, isLoading: isCreating } = useCreateFirstUser();
    const formik = useFormik<CreateFirstUserRequest>({
        initialValues: {
            code: props.code,
            company_id: props.company_id,
            user: {
                first_name: "",
                last_name: "",
                email: "",
                role: Role.ADMIN
            }
        },
        validateOnBlur: true,
        validateOnChange: true,
        onSubmit: () => {
            createUser(formik.values).then((response) => {
                if (response?.user) {
                    props.onSuccess();
                } else if ((response as any)?.detail?.errorCode === "username_exists") {
                    formik.setErrors({user: {email: language.users.emailExists}})
                }
            }).catch((error) => console.log(error))
        },
        validate: () => {

        }
    });

    return <Container sx={styles.container}>
        <Stack direction={"column"} spacing={2}>
            <Typography variant="h6" textAlign={"center"}>{language.company.registration.companySuccessful}</Typography>
            <TextField 
                name="user.first_name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                variant="outlined"
                label={language.users.firstName}
                sx={styles.textField}
                error={formik.touched.user?.first_name && !!formik.errors.user?.first_name}
                helperText={formik.touched.user?.first_name && formik.errors.user?.first_name}
            />
            <TextField 
                name="user.last_name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                variant="outlined"
                label={language.users.lastName}
                sx={styles.textField}
                error={formik.touched.user?.last_name && !!formik.errors.user?.last_name}
                helperText={formik.touched.user?.last_name && formik.errors.user?.last_name}
            />
            <TextField 
                name="user.email"
                onChange={(e) => {formik.handleChange(e); formik.setErrors({})}}
                onBlur={formik.handleBlur}
                variant="outlined"
                label={language.users.email}
                sx={styles.textField}
                error={formik.touched.user?.email && !!formik.errors.user?.email}
                helperText={formik.touched.user?.email && formik.errors.user?.email}
            />
            <Typography>
                {language.portal.readAndAccepted} <Link to={"/terms"}>{language.portal.termsAndConditions}</Link>
            </Typography>
            <Button
                variant="contained"
                sx={styles.button}
                onClick={() => formik.handleSubmit()}
                disabled={isCreating}
            >
                {language.generic.confirm}
            </Button>
        </Stack>
    </Container>
}